import React from "react"
import { Layout } from "../components/layout"
import { Link } from "../components/Link"
import {
  Hero,
  HeroColumns,
  HeroImage,
  Container,
  FeatureHighlight,
} from "@revfluence/aspire-www-ui"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { PageSection } from "../components/page-section"
import SEO from "../components/seo"

const HeaderContent = styled.div`
  padding-bottom: 32px;
`
const DownloadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export default function Data({ data }) {
  console.log(data)
  return (
    <Layout>
      <SEO
        title="2020 Holiday Consumer Data wrapped in a bow!"
        description="How are consumers, creators, and brands reacting to this holiday season? We ran the data to power your marketing with the facts. Download the infographic "
        url="holiday.aspireiq.com/data"
      />
      <Hero>
        <HeroColumns
          headline="Data wrapped in a bow"
          Copy={() => (
            <div>
              <HeaderContent>
                How are consumers, creators, and brands reacting to this holiday
                season? We ran the data to power your marketing with the facts
              </HeaderContent>
              <HeaderContent>View the infographic to discover:</HeaderContent>
              <ul>
                <li>
                  The impact of social media on the year's biggest shopping
                  season
                </li>
                <li>What matters more: brand loyalty or discounts?</li>
                <li>How creator campaigns are changing</li>
              </ul>
            </div>
          )}
          Cta={() => null}
          style={""}
          Media={(p) => {
            return (
              <HeroImage
                {...p}
                Media={(props) => (
                  <Img
                    fixed={data.headerImage.childImageSharp.fixed}
                    {...props}
                    objectFit="contain"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                )}
              />
            )
          }}
        >
          {null}
        </HeroColumns>
      </Hero>
      <Container>
        <PageSection>
          <Img
            style={{ width: "100%" }}
            fluid={data.infographic.childImageSharp.fluid}
          />
        </PageSection>
        <PageSection>
          <DownloadContainer>
            <FeatureHighlight
              icon="file-download"
              headline="Download"
              Link={Link}
              url={
                "https://learn.aspireiq.com/rs/982-DON-266/images/Holiday_Infographic.pdf"
              }
            />
          </DownloadContainer>
        </PageSection>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    headerImage: file(relativePath: { regex: "/data-header/" }) {
      childImageSharp {
        fixed(width: 570, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    infographic: file(relativePath: { regex: "/holiday-infographic/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
